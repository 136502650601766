import { Typography } from '@mui/material'
import React from 'react'
import useStyles from './Styles'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import { ButtonComponent } from 'src/components/shared/atoms'
import { joinCommunityInterface } from 'src/types/store/index'
import { useRouter } from 'next/router'
import Img from 'src/assets/images/getInvolved/joinCommuinty.jpg'
import Image from 'next/image'

interface Props {
  zenMode: boolean
  cardData: joinCommunityInterface
}
function JoinCommunity(props: Props) {
  const { zenMode, cardData } = props
  const router = useRouter()
  const { classes } = useStyles()

  return (
    <Box
      className={!zenMode ? classes.container : classes.containerZenMode}
      mb={0}
      sx={{ position: 'relative', overflow: 'hidden' }}
    >
      {!zenMode && (
        <div
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        >
          <Image src={Img?.src} alt={'background'} fill />
        </div>
      )}

      <Grid
        container
        py={{ md: 13, sm: 12, xs: 10 }}
        pb={{ md: 13, sm: 12, xs: 4 }}
      >
        <Grid
          item
          md={8}
          pl={{ md: 15, sm: 10, xs: 6 }}
          pr={{ md: 15, sm: 10, xs: 6 }}
          sx={{ zIndex: 1 }}
        >
          <Typography className={classes.heading}>
            {cardData.heading}
          </Typography>
          <Typography className={classes.text}>
            {cardData.description}
          </Typography>
        </Grid>
        <Grid
          item
          md={4}
          sm={4}
          xs={12}
          pr={{ md: 15, sm: 6, xs: 6 }}
          pl={{ md: 0, sm: 6, xs: 6 }}
          pt={{ md: 0, sm: 10, xs: 6 }}
        >
          <div className={classes.btnContainer}>
            <ButtonComponent
              type="contained"
              text={cardData.button.content}
              className={classes.recommendBtn}
              gradient
              wrapperClass={classes.wrapperClassBtn}
              onClick={() => {
                router.push(cardData.button.href)
              }}
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  )
}

export default JoinCommunity
