import React from 'react'

export default function SearchIcon(): JSX.Element {
  return (
    <svg
      width="17"
      height="15"
      viewBox="0 0 17 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.17115 1.60006C1.92126 0.850175 2.93849 0.428913 3.99915 0.428913C5.0598 0.428913 6.07703 0.850175 6.82715 1.60006L7.99915 2.77106L9.17115 1.60006C9.54013 1.21802 9.98151 0.913289 10.4695 0.703653C10.9575 0.494017 11.4824 0.383673 12.0135 0.379057C12.5447 0.374442 13.0714 0.475649 13.563 0.676772C14.0545 0.877896 14.5012 1.17491 14.8767 1.55048C15.2523 1.92605 15.5493 2.37266 15.7504 2.86424C15.9516 3.35583 16.0528 3.88255 16.0481 4.41366C16.0435 4.94478 15.9332 5.46966 15.7236 5.95768C15.5139 6.44569 15.2092 6.88707 14.8271 7.25606L7.99915 14.0851L1.17115 7.25606C0.421261 6.50595 0 5.48872 0 4.42806C0 3.3674 0.421261 2.35017 1.17115 1.60006V1.60006Z"
        fill="#6D7AA2"
      />
    </svg>
  )
}
