import React from 'react'

export default function SearchIcon(): JSX.Element {
  return (
    <svg
      width="6"
      height="11"
      viewBox="0 0 6 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.292787 10.5863C0.105316 10.3988 0 10.1445 0 9.87931C0 9.61415 0.105316 9.35984 0.292787 9.17231L3.58579 5.87931L0.292787 2.58631C0.110629 2.39771 0.00983372 2.14511 0.0121121 1.88291C0.0143906 1.62072 0.11956 1.3699 0.304968 1.1845C0.490376 0.999087 0.741189 0.893918 1.00339 0.891639C1.26558 0.889361 1.51818 0.990156 1.70679 1.17231L5.70679 5.17231C5.89426 5.35984 5.99957 5.61415 5.99957 5.87931C5.99957 6.14448 5.89426 6.39879 5.70679 6.58631L1.70679 10.5863C1.51926 10.7738 1.26495 10.8791 0.999786 10.8791C0.734622 10.8791 0.480314 10.7738 0.292787 10.5863Z"
        fill="white"
      />
    </svg>
  )
}
