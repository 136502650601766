import React from 'react'

export default function SearchIcon(): JSX.Element {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 7.37891C16 11.2449 12.418 14.3789 8 14.3789C6.58005 14.3849 5.17955 14.0487 3.917 13.3989L0 14.3789L1.338 11.2559C0.493 10.1459 0 8.81291 0 7.37891C0 3.51291 3.582 0.378906 8 0.378906C12.418 0.378906 16 3.51291 16 7.37891ZM5 6.37891H3V8.37891H5V6.37891ZM13 6.37891H11V8.37891H13V6.37891ZM7 6.37891H9V8.37891H7V6.37891Z"
        fill="#6D7AA2"
      />
    </svg>
  )
}
