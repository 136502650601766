/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import useStyles from './Style'
import axios from 'axios'
import { useState, useEffect } from 'react'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'

import { ButtonComponent, RoundTextField } from 'src/components/shared/atoms'
import Divider from '@mui/material/Divider'
import { Grid } from '@mui/material'
import Arrow from 'src/assets/svgs/icons/arrowRight'
import Avatar from '@mui/material/Avatar'
import Stack from '@mui/material/Stack'
import Comments from 'src/assets/svgs/icons/comment'
import Reacts from 'src/assets/svgs/icons/reacts'
import JoinCommunity from '../joinCommunity'
import { joinCommunityInterface, NeedASafeSpaceI } from 'src/types/store/index'
import { SearchResults } from 'src/types/components/shared/modules'

import { useGetCommunityDataMutation } from 'src/store/ApiCall/getCommunityData'
import { useHomeApiCallQuery } from 'src/store/ApiCall/authApiCalls'

import { CommunityI } from 'src/types'
import { htmlToText } from 'html-to-text'
import Moment from 'react-moment'
import { getCookie } from 'cookies-next'
import { useRouter } from 'next/router'
interface Props {
  zenMode: boolean
  community: boolean
  content: NeedASafeSpaceI
  search?: boolean
  searchQuery?: string
  partners?:boolean
}

interface CommunityData {
  profile: string
  userName: string
  commentText: string
  profileUrl: string
  url: string
  postDate: string
  reactCounts: number[]
  item_id: number
}

const enActiveTag = {
  id: 7,
  text: 'Mental Health',
}
const esActiveTag = {
  id: 9,
  text: 'Salud mental',
}
const enTags = [
  {
    id: 7,
    text: 'Mental Health',
  },
  { id: 5, text: 'Bullying' },
  {
    id: 6,
    text: 'Identity',
  },
  {
    id: 13,
    text: 'WWYD?',
  },
  {
    id: 58,
    text: 'Gender & Dating',
  },

  {
    id: 8,
    text: 'Relationships',
  },
]
const mxTags = [
  {
    id: 9,
    text: 'Salud mental',
  },
  { id: 7, text: 'Sexualidad y género' },
  { id: 22, text: '¿Qué harías tú? Dilemas' },
  { id: 10, text: 'Bullying e Intimidación' },
  { id: 8, text: 'Relaciones y citas' },
]
function TopPosts({ zenMode, community, content, search, searchQuery, partners }: Props) {
  const [getCommunityData, { isLoading }] = useGetCommunityDataMutation()

  const userToken: string | null =
    typeof window === 'object' ? localStorage.getItem('dtl_token') : null

  const loggedInUserDetails = useHomeApiCallQuery({
    id: userToken ? JSON.parse(userToken).id : 0,
  })

  const lang = getCookie('lang')
  const router = useRouter()
  const [commentInfo, setCommentInfo] = React.useState<CommunityData[]>()
  const [activeTag, setActiveTag] = React.useState(
    lang === 'En' ? enActiveTag : esActiveTag
  )

  const { classes } = useStyles()

  const [tags] = React.useState(lang === 'En' ? enTags : mxTags)

  const activeLang = () => {
    if (lang === 'es-MX') {
      return 'Es'
    }
    return 'En'
  }

  React.useEffect(() => {
    async function getData() {
      const data = await getCommunityData({
        topic: activeTag.id,
        locale: activeLang(),
      }).unwrap()
      if (data && data.length > 0) {
        const preProcessingData = data?.map((item: CommunityI) => {
          return {
            profile: item?.author?.photoUrl,
            userName: item?.author.name,
            commentText: item?.content,
            profileUrl: item?.author?.profileUrl,
            url: item?.url,
            postDate: item?.date,
            reactCounts: item.reactions,
            item_id: item.item_id,
          }
        })
        setCommentInfo(preProcessingData)
      }
    }

    getData()
  }, [getCommunityData])

  function limitWords(str: string, limit: number) {
    const words = str.split(' ')
    if (words.length <= limit) {
      return str
    }
    const limitedWords = words.slice(0, limit).join(' ')
    return limitedWords
  }

  const [joinCommunity] = React.useState<joinCommunityInterface>(content)

  // function getColorById(id: number) {
  //   const colors = ['#859B88', '#6D7AA2', '#EE92B3', '#EAA36D', '#859B88']
  //   const index = id % colors.length
  //   return colors[index]
  // }

  const clickHandler = (id: number) => {
    async function getData() {
      const data = await getCommunityData({
        topic: id,
        locale: activeLang(),
      }).unwrap()
      if (data && data.length > 0) {
        const preProcessingData = data?.map((item: CommunityI) => {
          return {
            profile: item?.author?.photoUrl,
            profileUrl: item?.author?.profileUrl,
            url: item?.url,
            userName: item?.author.name,
            commentText: item?.content,
            postDate: item?.date,
            reactCounts: item.reactions,
            item_id: item.item_id,
          }
        })
        setCommentInfo(preProcessingData)
      }
    }
    getData()
  }

  //// search
  const [searchData, setSearchData] = useState<SearchResults[]>([])
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `/api/invision-search?query=${searchQuery}`
        )
        setSearchData(response.data)
      } catch (error) {
        // Handle error if needed
        console.error('Error fetching data:', error)
      }
    }
    if (search) {
      fetchData()
    }
  }, [searchQuery])

  //ends

  // check user logged in
  const [authenticated, setAuthenticated] = React.useState(false)

  React.useEffect(() => {
    const userToken: string | null =
      typeof window === 'object' ? localStorage.getItem('dtl_token') : null
    if (userToken) {
      if (JSON.parse(userToken).id) setAuthenticated(true)
    }
  }, [])

  // check user logged in ends

  // post request on invision start

  const [createPost, setCreatePost] = React.useState('')

  const postInCommunity = async () => {
    const data = {
      invisionid: loggedInUserDetails
        ? loggedInUserDetails.data?.data.attributes.invision_id
        : -1,
      topicId: commentInfo ? commentInfo[0]?.item_id : -1,
      content: `<p>${createPost}</p>`,
      lang,
    }
    try {
      await axios.post(`/api/invision-post`, data)
      setCreatePost('')
      clickHandler(activeTag.id)
    } catch (error) {
      console.error('Error fetching data:', error)
    }
  }

  const enterKeyHandler = (e: React.KeyboardEvent) => {
    console.log(e.key)

    if (e.key === 'Enter' && createPost) {
      console.log('ins', e.key)

      postInCommunity()
    }
    return
  }

  /// post request on invision ends here

  const regexForContent = (inputString: string) => {
    const blockquoteRegex = /<blockquote[^>]*>[\s\S]*?<\/blockquote>/g
    const withoutBlockquote = inputString.replace(blockquoteRegex, '')
    const pTagRegex = /<\/?p>/g
    const withoutPTags = withoutBlockquote.replace(pTagRegex, '')
    const withoutSpacingAndQuotes = withoutPTags
      .replace(/\n/g, '')
      .replace(/"|\s{2,}/g, '')
    return withoutSpacingAndQuotes
  }

  const userAgeHandler = () => {
    const userAge = Number(loggedInUserDetails?.data?.data?.attributes?.age)
    return userAge >= 12 && userAge <= 25
  }

  return (
    <div
      className={`${classes.outerContainer} ${
        partners && classes.bgOuterContainer
      } ${zenMode && classes.zenModeImg}`}
    >
      <div className={classes.innerContainer}>
        <div className={`${classes.root} ${zenMode && classes.zenModeImg} `}>
          <Box className={`${!community && classes.community}`}>
            <JoinCommunity zenMode={zenMode} cardData={joinCommunity} />
          </Box>
          {!search && !community && (
            <Box className={classes.header}>
              <Typography
                className={`${classes.title} ${
                  zenMode && classes.zenModeColor
                }`}
              >
                TOP POSTS FROM COMMUNITY
              </Typography>
              <div>
                <ButtonComponent
                  type="contained"
                  text="GO TO COMMUNITY"
                  className={classes.btn}
                  gradient
                />
              </div>
            </Box>
          )}
          {!search && !community && <Divider className={classes.divider} />}

          {!search && (
            <Grid
              container
              // columnSpacing={{ md: 10, sm: 10, xs: 10 }}
              mt={{ md: 15, sm: 10, xs: 4 }}
            >
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box
                  className={classes.mobileViewToggle}
                  pr={{ md: 10, sm: 10, xs: 10 }}
                >
                  {tags?.map((tag) => {
                    return (
                      <Box
                        className={`${classes.tags} ${
                          activeTag.id === tag.id && classes.tagActive
                        } `}
                        key={tag.id}
                        onClick={() => {
                          setActiveTag(tag)
                          clickHandler(tag.id)
                        }}
                      >
                        <Typography
                          className={`${classes.tagName} ${
                            zenMode && classes.zenModeColor
                          }`}
                        >
                          {tag.text}
                        </Typography>
                        <Arrow />
                      </Box>
                    )
                  })}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={8} lg={8}>
                <Box className={classes.commentWrapper}>
                  {!isLoading ? (
                    commentInfo
                      ?.sort(
                        (a, b) =>
                          Date.parse(String(new Date(a.postDate))) -
                          Date.parse(String(new Date(b.postDate)))
                      )
                      .reverse()
                      .map((comment, idx) => {
                        return (
                          <Box key={idx}>
                            {idx !== 0 && (
                              <Divider className={classes.dividerComment} />
                            )}

                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Avatar
                                // onClick={() => router.push(comment.profileUrl)}
                                alt={comment.userName}
                                src={comment.profile}
                                className={classes.avatar}
                              />
                              <Stack direction="column" spacing={0.5}>
                                <Typography
                                  className={`${classes.tagNameAvatar} ${
                                    zenMode && classes.zenModeColor
                                  }`}
                                >
                                  {activeTag.text}
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={3}
                                  alignItems="center"
                                >
                                  <Typography
                                    // onClick={() =>
                                    //   router.push(comment.profileUrl)
                                    // }
                                    className={`${classes.userName} ${
                                      zenMode && classes.zenModeColor
                                    }`}
                                  >
                                    {comment.userName}
                                  </Typography>
                                  <Box className={classes.dot}></Box>
                                  <Typography
                                    className={`${classes.userName} ${
                                      zenMode && classes.zenModeColor
                                    }`}
                                  >
                                    <Moment fromNow>{comment.postDate}</Moment>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>

                            <Typography
                              className={`${classes.commentText} ${
                                zenMode && classes.zenModeColor
                              }`}
                              onClick={() => router.push(comment.url)}
                              sx={{ cursor: 'pointer' }}
                            >
                              {limitWords(
                                htmlToText(
                                  regexForContent(comment.commentText)
                                ),
                                35
                              )}
                              {
                                <span className={classes.showMoreButton}>
                                  ...more
                                </span>
                              }
                            </Typography>

                            <Stack
                              direction="row"
                              columnGap={2}
                              justifyContent="start"
                              alignItems="center"
                              flexWrap="wrap"
                            >
                              {/* {comment?.tags?.length > 0 &&
                                comment.tags.map((tag, idx) => {
                                  return (
                                    <Box
                                      className={`${classes.chips}`}
                                      sx={{
                                        background: !zenMode
                                          ? getColorById(idx)
                                          : 'none',
                                        border: zenMode ? '1px solid black' : '0',
                                      }}
                                      key={idx}
                                      mb={8}
                                    >
                                      <Typography
                                        className={`${classes.chipsText} ${zenMode && classes.zenModeColor
                                          }`}
                                      >
                                        {' '}
                                        {tag}
                                      </Typography>
                                    </Box>
                                  )
                                })} */}
                            </Stack>

                            <Stack
                              direction="row"
                              spacing={10}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Stack
                                direction="row"
                                spacing={1.5}
                                justifyContent="start"
                                alignItems="center"
                              >
                                {comment?.reactCounts?.length > 0 && <Reacts />}
                                <Typography
                                  className={`${classes.count} ${
                                    zenMode && classes.zenModeColor
                                  }`}
                                >
                                  {comment?.reactCounts?.length > 0 &&
                                    comment?.reactCounts?.length}{' '}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Box>
                        )
                      })
                  ) : (
                    <Typography className={classes.loader}>
                      Loading...
                    </Typography>
                  )}
                </Box>
                {loggedInUserDetails && userAgeHandler() && authenticated && (
                  <Box sx={{ width: '100%', marginTop: '20px' }}>
                    <RoundTextField
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setCreatePost(event.target.value)
                      }}
                      onKeyDown={enterKeyHandler}
                      placeholder={
                        lang === 'En'
                          ? 'Share your thoughts'
                          : 'Compartir tus pensamientos'
                      }
                      className={classes.inputPost}
                      value={createPost}
                    />
                  </Box>
                )}
              </Grid>
            </Grid>
          )}
          {search && (
            <Grid container mt={{ md: 15, sm: 10, xs: 4 }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Box className={classes.commentWrapper}>
                  {searchData.length > 0 ? (
                    searchData
                      ?.sort(
                        (a, b) =>
                          Date.parse(String(new Date(a.updated))) -
                          Date.parse(String(new Date(b.updated)))
                      )
                      .reverse()
                      .map((search, idx) => {
                        return (
                          <Box key={idx}>
                            {idx !== 0 && (
                              <Divider className={classes.dividerComment} />
                            )}

                            <Stack
                              direction="row"
                              spacing={2}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Avatar
                                alt={search.author}
                                src={search.authorPhoto}
                                className={classes.avatar}
                              />
                              <Stack direction="column" spacing={0.5}>
                                <Typography
                                  className={`${classes.tagNameAvatar} ${
                                    zenMode && classes.zenModeColor
                                  }`}
                                >
                                  {search.container}
                                </Typography>
                                <Stack
                                  direction="row"
                                  spacing={3}
                                  alignItems="center"
                                >
                                  <Typography
                                    onClick={() =>
                                      router.push(search.authorUrl)
                                    }
                                    className={`${classes.userName} ${
                                      zenMode && classes.zenModeColor
                                    }`}
                                  >
                                    {search.author}
                                  </Typography>
                                  <Box className={classes.dot}></Box>
                                  <Typography
                                    className={`${classes.userName} ${
                                      zenMode && classes.zenModeColor
                                    }`}
                                  >
                                    <Moment fromNow>{search.started}</Moment>
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                            <Typography
                              className={`${classes.commentText} ${
                                zenMode && classes.zenModeColor
                              }`}
                              onClick={() => router.push(search.objectUrl)}
                            >
                              {limitWords(htmlToText(search.content), 35)}
                              {
                                <span
                                  onClick={() => router.push(search.objectUrl)}
                                  className={classes.showMoreButton}
                                >
                                  more
                                </span>
                              }
                            </Typography>
                            <Stack
                              direction="row"
                              columnGap={2}
                              justifyContent="start"
                              alignItems="center"
                              flexWrap="wrap"
                            ></Stack>

                            <Stack
                              direction="row"
                              spacing={10}
                              justifyContent="start"
                              alignItems="center"
                            >
                              <Stack
                                direction="row"
                                spacing={1.5}
                                justifyContent="start"
                                alignItems="center"
                              >
                                {<Comments />}
                                <Typography
                                  className={`${classes.count} ${
                                    zenMode && classes.zenModeColor
                                  }`}
                                >
                                  {search.comments}
                                </Typography>
                              </Stack>
                            </Stack>
                          </Box>
                        )
                      })
                  ) : (
                    <Typography className={classes.loader}>
                      Loading...
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          )}
        </div>
      </div>
    </div>
  )
}

export default TopPosts
