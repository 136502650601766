import { makeStyles } from 'tss-react/mui'
import { nunito_sans } from '@styles/fonts'
import Noise from 'src/assets/images/campaigns/noise.png'
import bg from 'src/assets/images/partner/topPostBg.png'
import { lato } from '@styles/fonts'
const useStyles = makeStyles()((theme) => ({
  outerContainer: {
    height: 'auto',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },

  bgOuterContainer:{
    paddingTop:theme.spacing(16),
    paddingBottom:theme.spacing(16),
    backgroundImage:`url(${bg?.src}) `
  },

  innerContainer: {
    maxWidth: theme.maxWidth,
    width: '100%',
    padding: theme.spacing(0, 16),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      padding: theme.spacing(0, 0),
    },
  },
  root: {
    borderRadius: '60px',
    background: `url(${Noise.src}) #47506D`,
    padding: theme.spacing(12, 11, 12),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(8, 6, 12),
      borderRadius: '40px',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  community: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
    },
  },
  title: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 800,
    fontSize: '48px',
    lineHeight: '83px',
    textAlign: 'start',
    letterSpacing: ' 0.02em',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      fontSize: '34px',
      lineHeight: '53px',
      marginLeft: theme.spacing(3),
    },
  },
  btn: {
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },
  divider: {
    border: '.5px solid #C5CED6',
    marginTop: theme.spacing(9),
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  tags: {
    borderRadius: '138px',
    padding: theme.spacing(5, 5),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    marginBottom: theme.spacing(5),
    border: '1px solid grey',
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3, 3),
      marginRight: theme.spacing(5),
    },
    '&:hover': {
      background: 'linear-gradient(45deg, #EE92B3 0%, #6D7AA2 100%)',
    },
  },
  tagActive: {
    background: 'linear-gradient(45deg, #EE92B3 0%, #6D7AA2 100%)',
  },
  tagName: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '25px',
    textAlign: 'start',
    textTransform: 'uppercase',
    color: '#FFFFFF',
    [theme.breakpoints.down('md')]: {
      fontSize: '14px',
      lineHeight: 'normal',
      marginRight: '12px',
    },
  },

  avatar: {
    height: '32px',
    width: '32px',
    // cursor: 'pointer',
  },
  tagNameAvatar: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '19px',
    textAlign: 'start',
    color: '#FFFFFF',
  },
  userName: {
    // cursor: 'pointer',
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '14px',
    textAlign: 'start',
    color: '#B4B4B4',
  },
  showMoreButton: {
    cursor: 'pointer',
    color: 'lightblue',
  },
  commentWrapper: {
    height: '610px',
    overflow: 'auto',
    textAlign: 'center',
  },
  loader: {
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '460px',
    color: '#ffffff',
    fontStyle: 'normal',
    fontWeight: 400,
    fontFamily: lato.style.fontFamily,
    fontSize: '16px',
  },
  dot: {
    width: '6px',
    height: '6px',
    backgroundColor: '#B4B4B4',
    borderRadius: '50%',
    display: 'inline-block',
  },
  commentText: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '19px',
    textAlign: 'start',
    color: '#ffffff',
    padding: theme.spacing(4, 0),
    p: {
      // textTransform: 'lower-case',
    },
  },
  inputPost: {
    width: '100%',
    '& .MuiOutlinedInput-root': {
      color: 'white',
      '&.Mui-focused fieldset': {
        borderColor: 'white',
      },
    },
  },
  count: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '14px',
    textAlign: 'start',
    color: '#ffffff',
  },
  dividerComment: {
    border: '.5px solid #C5CED6',
    margin: theme.spacing(5, 0),
  },

  chips: {
    borderRadius: '36px',
    padding: theme.spacing(2.5, 3, 3),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    marginBottom: '20px !important',
    [theme.breakpoints.down('md')]: {
      marginBottom: '10px !important',
    },
  },
  chipsText: {
    fontFamily: lato.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '13px',
    lineHeight: '0px',
    textAlign: 'center',
    color: '#ffffff',
  },
  mobileViewToggle: {
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingBottom: theme.spacing(0),
      margin: theme.spacing(5, 0, 7, 0),
      display: 'flex',
      whiteSpace: 'nowrap',
      overflow: 'scroll',
      overflowY: 'visible',
      overflowX: 'scroll',
    },
  },
  zenModeImg: {
    background: 'none',
    border: '1px solid black',
  },
  zenModeColor: {
    color: '#000000',
  },
}))

export default useStyles
