import { makeStyles } from 'tss-react/mui'
// import Img from 'src/assets/images/getInvolved/joinCommuinty.jpg'
import { nunito_sans } from '@styles/fonts'

const useStyles = makeStyles()((theme) => ({
  root: {},
  container: {
    height: 'auto',
    // backgroundImage: `url(${Img?.src})`,
    backgroundRepeat: 'no-repeat',
    borderRadius: '40px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    [theme.breakpoints.down('md')]: {
      backgroundSize: 'cover',
    },
  },
  containerZenMode: {
    // margin: theme.spacing(32, 0),
    height: 'auto',
    border: '1px solid black',
    backgroundRepeat: 'no-repeat',
    borderRadius: '45px',
    backgroundPosition: 'center',
    backgroundSize: '100% 100%',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(12, 0, 12),
    },
  },
  heading: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: ' normal',
    fontWeight: '800',
    fontSize: '64px',
    lineHeight: '72px',
    textTransform: 'uppercase',
    color: '#000000',
    textAlign: 'start',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      width: '100%',
      lineHeight: 'normal',
      textAlign: 'center',
    },
    span: {
      position: 'relative',
      top: '10px',
      img: {
        marginTop: '-1rem',
        paddingLeft: '.5rem',
      },
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
  },

  // hideOnSm: {
  //   [theme.breakpoints.down('sm')]: {
  //     display: 'none',
  //   },
  // },
  viewOnSm: {
    display: 'none',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',

      // marginTop: theme.spacing(8),
      img: {
        paddingLeft: '4px',
        width: '45px',
        height: '44px',
      },
    },
  },
  text: {
    width: '95%',
    ...theme.textPrimary,
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '20px',
    lineHeight: '24px',
    color: '#000000',
    opacity: '0.7',
    textAlign: 'start',
    marginTop: theme.spacing(2),

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },
  },
  btn: {
    fontFamily: nunito_sans.style.fontFamily,
    fontStyle: 'normal',
    color: theme.custom.lightFont,
    backgroundColor: theme.custom.blackColor,
    ':hover': {
      background: theme.custom.blackColor,
    },
  },

  btnContainer: {
    height: '100%',
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'start',
    },
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'start',
    },
  },
  recommendBtn: {
    background: 'black',
    color: 'white',
    '&:hover': {
      background: 'white',
      color: 'black',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flex: 1,
    },
  },
  wrapperClassBtn: {
    width: 'auto !important',
    [theme.breakpoints.down('sm')]: {
      width: '100% !important',
    },
  },
}))

export default useStyles
